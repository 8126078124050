import apiList from "./api";
// 以插件的形式把API赋值到到vue的原型上
const install = function(Vue) {
  if (install.installed) {
    return;
  }
  install.installed = true;
  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return apiList;
      }
    }
  });
};
export default {
  install
};
