export default [
  {
    path: "/index",
    name: "index",
    meta: {
      title: "AI测肤",
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/loading/index.vue")
  },
  {
    path: "/animate",
    name: "animate",
    meta: {
      title: "AI测肤",
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "animate" */ "@/views/facialfeatures/animate/index.vue"
      )
  }
];
