/****   http.js   ****/
// 导入封装好的axios实例
// import Vue from "vue";
// import { Toast } from "vant";
// Vue.use(Toast);
import request from "./request";
function getHeader() {
  return {
    mallToken: localStorage.getItem("mallToken")
    // mallToken: "MzU3ODA3LTEtZGFhYzUyOTEtZjVkNy00NjVmLTlhOTEtYzEzNGUwY2JjNmM2"
  };
}
const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get(url, params) {
    const config = {
      method: "get",
      url: url,
      headers: getHeader()
    };
    if (params) config.params = params;
    return request(config);
    // return new Promise((resolve, reject) => {
    //   request(config).then(res => {
    //     if (res.data.success) {
    //       resolve(res);
    //     } else {
    //       reject(res.data);
    //       Vue.prototype.$toast(res.data.errorMessage);
    //     }
    //   });
    // });
  },
  post(url, params) {
    const config = {
      method: "post",
      url: url,
      headers: getHeader()
    };
    if (params) config.data = params;
    return request(config);
  },
  put(url, params) {
    const config = {
      method: "put",
      url: url,
      headers: getHeader()
    };
    if (params) config.params = params;
    return request(config);
  },
  delete(url, params) {
    const config = {
      method: "delete",
      url: url,
      headers: getHeader()
    };
    if (params) config.params = params;
    return request(config);
  }
};
//导出
export default http;
