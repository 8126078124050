import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    imgSrc: "",
    result: {},
    faceData: null,
    userInfoWechat: {},
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfoWechat = JSON.parse(payload.userInfo);
    }
  },
  actions: {},
  modules: {}
});
