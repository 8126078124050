import Vue from "vue";
import "vant/lib/index.css";
import {
  Cell,
  CellGroup,
  Button,
  NoticeBar,
  Collapse,
  CollapseItem,
  Icon,
  Field,
  Popup,
  DatetimePicker,
  List,
  Toast,
  Dialog,
  Circle,
  Tab,
  Tabs
} from "vant";
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(List);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Circle);
Vue.use(Tab);
Vue.use(Tabs);
