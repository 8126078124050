import Vue from "vue";
import App from "./App.vue";
import "./vantcoments/vant";
import router from "./router";
import store from "./store";
import "lib-flexible";
import "./styles/comen.css";
// import Vconsole from "vconsole";
import animated from "animate.css";
import api from "./api/install";
//使用api插件
// eslint-disable-next-line no-unused-vars
// var vconsole = new Vconsole();
Vue.use(api);
Vue.config.productionTip = false;
Vue.use(animated);
router.beforeEach((to, from, next) => {
  // localStorage.setItem(
  //   "mallToken",
  //   "MzU3ODA3LTEtZGFhYzUyOTEtZjVkNy00NjVmLTlhOTEtYzEzNGUwY2JjNmM2"
  // );
  if (to.query.userInfo && JSON.stringify(store.state.userInfoWechat) == "{}") {
    store.commit("setUserInfo", {
      userInfo: to.query.userInfo
    });
    localStorage.setItem("mallToken", JSON.parse(to.query.userInfo).mallToken);
    localStorage.setItem("uuid", JSON.parse(to.query.userInfo).uuid);
    localStorage.setItem("testType", JSON.parse(to.query.userInfo).testType);
  }
  next();
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
